input[type="file"] {
	width: 0;
	height: 0;
	opacity: 0;
	position: absolute;
	z-index: -1;
  }
  
  /* Style the custom file upload label */
  label.custom-file-upload {
	padding: 8px 12px;
	background-color: #f2f2f2;
	border: 1px solid #ccc;
	border-radius: 4px;
	cursor: pointer;
  }
  